import moment from 'moment';

function TableSubjectTeachingTeacher(props) {
    return (
        <div>
            {
                props.data != null || props.data != {} ? <div className={'overflow-x-auto'}>
                    <h3 className={'text-uppercase py-3 font-bold text-center text-2xl'}>Danh sách lịch học</h3>
                    <div className={'overflow-x-auto'}>
                        <table className='w-full table-bordered divide-y divide-gray-900 border-2'
                               style={{ fontSize: 13, minWidth: 1000 }}>
                            <tbody>
                            <tr className={'bg-green-800 text-white'}>
                                <td>Ngày</td>
                                <td>Buổi</td>
                                {
                                    props.data.teachers?.length > 0 ? props.data.teachers?.map((room) => {
                                        return <td>{room.teacherName}</td>;
                                    }) : <td></td>
                                }
                            </tr>
                            {
                                props.data.times?.length > 0 ? props.data.times.map((time, index) => {
                                        return <>
                                            <tr>
                                                <td rowSpan='2'
                                                    className={'font-bold p-2'}>{moment(time).format('dddd DD/MM/YYYY')}</td>
                                                <td className={'bg-blue-100'}>S</td>
                                                {
                                                    props.data.teachers?.length > 0 ? props.data.teachers.map((room) => {
                                                        return <td
                                                            className={'bg-blue-100 ' + ( room.season.morning[index] != null ? room.season.morning[index]['subjectTeachingName'].search('Thi:') >= 0 ? 'text-red-600 font-medium' : '' : '')}>{room.season.morning[index] != null ? room.season.morning[index]['subjectTeachingName'] + `${' - ' + room.season.morning[index]['roomName']}` : ''}</td>;
                                                    }) : <td>''</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td className={'bg-yellow-50'}>C</td>
                                                {
                                                    props.data.teachers?.length > 0 ? props.data.teachers.map((room) => {
                                                        return <td
                                                            className={'bg-yellow-50 ' + ( room.season.afternoon[index] != null ? room.season.afternoon[index]['subjectTeachingName'].search('Thi:') >= 0 ? 'text-red-600 font-medium' : '' : '')}>{room.season.afternoon[index] != null ? room.season.afternoon[index]['subjectTeachingName'] + `${' - ' + room.season.afternoon[index]['roomName']}` : ''}</td>;
                                                    }) : <td>''</td>
                                                }
                                            </tr>
                                        </>;
                                    }
                                ) : ''
                            }
                            </tbody>
                        </table>
                    </div>
                </div> : 'Không có dữ liệu lịch học!'
            }
        </div>
    );
}

export default TableSubjectTeachingTeacher;
