import React  from 'react';
import moment from 'moment/moment';
import Moment from 'react-moment';

function TableScheduleTeachingDetails({attendances}) {
    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr className={'text-center'}>
                <th
                    scope='col'
                    className='px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    MSSV
                </th>
                <th
                    scope='col'
                    className='px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Họ tên
                </th>
                <th
                    scope='col'
                    className='px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Lớp
                </th>
                <th
                    scope='col'
                    className='px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tổng số tiết trễ
                </th>
                <th
                    scope='col'
                    className='px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tổng số tiết vắng có phép
                </th>
                <th
                    scope='col'
                    className='px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tổng số tiết vắng không phép
                </th>
                <th
                    scope='col'
                    className='px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tỷ lệ vắng
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {
                attendances.map(record => {
                    return <tr
                        className={(record?.percent > 30 ? 'bg-red-700 text-white hover:bg-red-400': 'bg-white text-gray-900 hover:bg-gray-400')}
                        onClick={(e) => {
                            // props.clicked(e, item.id);
                        }}>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm font-medium'>
                                {record?.userInternalId}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm font-medium'>
                                {record?.fullName}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm font-medium'>
                                {record?.className}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm font-medium'>
                                {record?.numberOfT}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm font-medium'>
                                {record?.numberOfP}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm font-medium'>
                                {record?.numberOfK}
                            </div>
                        </td>
                        <td className={'px-6 py-4 whitespace-nowrap'}>
                            <div className={'text-sm font-medium fw-bold'}>
                                {record?.percent}%
                            </div>
                        </td>
                    </tr>
                })
            }
            </tbody>
        </table>
    );
}

export default TableScheduleTeachingDetails;