import { _ENUMS } from 'constants/_enums';
import './dialog-student.scss';
import React, { useEffect, useState } from 'react';
import { $api } from 'services/service';
import { setAlert, setLoading } from 'redux/authSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import Button from '../Button/Button';

export default function DialogStudent({ reloadData }) {
    let { idSubjectTeaching } = useParams();
    const dispatch = useDispatch();
    const [change, setChange] = useState(false);
    const [studentsSelected, setStudentsSelected] = useState([]);
    const [mode, setMode] = useState(0);
    const [studentInSubject, setStudentInSubject] = useState([]);
    const [students, setStudents] = useState([]);
    const [classes, setClasses] = useState([]);
    const [studentRecommends, setStudentRecommends] = useState([]);
    const [classRecommends, setClassRecommends] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);

    useEffect(() => {
        async function fetchStudentInSubject(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectStudent_getPaging({
                    subjectTechingsId: id
                });
                setStudentInSubject(res.result);
                const a = res.result.map((item) => {
                    return item.studentId;
                });
                setStudentsSelected(a);

                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
                // dispatch(
                // 	setAlert({
                // 		type: 'danger',
                // 		title:
                // 			e?.response?.data?.errors?.exceptionMessage ||
                // 			'Lỗi',
                // 	})
                // );
            }
        }

        async function fetchAllRecommendation() {
            try {
                // const res: any = await $api.student.getPaging({
                // 	isGraduated: false,
                // });
                dispatch(setLoading(true));
                const res: any =
                    await $api.schedule.subjectStudent_GetListRecommendation(
                        idSubjectTeaching
                    );
                setStudentRecommends(res.result.students);
                setClassRecommends(res.result.classes);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
                // dispatch(
                // 	setAlert({
                // 		type: 'danger',
                // 		title:
                // 			e?.response?.data?.errors?.exceptionMessage ||
                // 			'Lỗi',
                // 	})
                // );
            }
        }

        if (idSubjectTeaching && idSubjectTeaching.length > 0) {
            fetchStudentInSubject(idSubjectTeaching);
            fetchAllRecommendation();
            setTimeout(() => reloadData(change), 700);
        }
    }, [change, idSubjectTeaching]);

    useEffect(() => {
        async function fetchAllStudent() {
            try {
                const res: any = await $api.student.getPaging({
                    isGraduated: false
                });

                setStudents(res.result.items);
            } catch (e) {
                dispatch(
                    setAlert({
                        type: 'danger',
                        title:
                            e?.response?.data?.errors?.exceptionMessage ||
                            'Lỗi'
                    })
                );
            }
        }

        async function fetchAllClass() {
            try {
                const res: any = await $api.class.getAll();
                setClasses(res.result);
            } catch (e) {
                dispatch(
                    setAlert({
                        type: 'danger',
                        title:
                            e?.response?.data?.errors?.exceptionMessage ||
                            'Lỗi'
                    })
                );
            }
        }

        fetchAllStudent();
        fetchAllClass();
    }, []);

    function addStudentToList(e) {
        e.stopPropagation();
        setMode(1);
    }

    async function createStudentInSubject() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectStudent_update({
                subjectTeachingId: idSubjectTeaching,
                studentIds: studentsSelected
            });
            // const res: any = await $api.schedule.subjectStudent_create({
            // 	subjectTeachingId: idSubjectTeaching,
            // 	studentIds: studentsSelected,
            // });
            dispatch(
                setAlert({
                    title: res?.message
                })
            );
            dispatch(setLoading(false));
            setChange(!change);
            setTimeout(() => {
                setMode(0);
            }, 500);
        } catch (e) {
        }
    }

    async function deleteStudentInSubject(e, idDelete) {
        e.stopPropagation();
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true,
            title: 'Thông báo',
            subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(idDelete);
    }

    async function addSelectedStudent(e, id: string) {
        e.stopPropagation();
        // @ts-ignore
        const index = studentsSelected.indexOf(id);
        // @ts-ignore
        studentsSelected.push(id);
        //  if (index !== -1) {
        // 	studentsSelected.splice(index, 1);
        //  }
        //  else {
        //  	// @ts-ignore
        // // 	studentsSelected.push(id);
        // // }
    }

    async function addSelectedStudent2(e, id: string) {
        e.stopPropagation();
        // @ts-ignore
        const index = studentsSelected.indexOf(id);
        if (index === -1) {
            // @ts-ignore
            studentsSelected.push(id);
        }
    }

    function back() {
        setMode(0);
        const a: any = studentInSubject.map((item: { studentId: string }) => {
            return item.studentId;
        });
        setStudentsSelected(a);
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            const array = studentInSubject
                .map((item: { studentId: string }) => {
                    return item.studentId;
                })
                .filter((item) => {
                    return item !== idDelete;
                });
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectStudent_update({
                subjectTeachingId: idSubjectTeaching,
                studentIds: array
            });
            dispatch(
                setAlert({
                    title: res?.message
                })
            );
            dispatch(setLoading(false));
            setChange(!change);
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true,
            subTitle: msg
        });
    }

    const [keyOfFilter, setKeyOfFilter] = useState({
        classFilter: '',
        studentFilter: '',
        actions: ''
    });
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [filteredClass, setFilteredClass] = useState([]);
    const [studentConfirmed, setStudentConfirmed] = useState([{}]);
    const [studentsForShow, setStudentsForShow] = useState([{}]);

    useEffect(() => {
        if (keyOfFilter.actions === 'student') {
            if (keyOfFilter.studentFilter.length) {
                setFilteredStudents(() => {
                    return students.filter(
                        (student) =>
                            // @ts-ignore
                            student.fullName
                                .toLowerCase()
                                .search(
                                    keyOfFilter.studentFilter.toLowerCase()
                                ) >= 0
                    );
                });
            } else {
                setFilteredStudents(students);
            }
        } else {
            if (keyOfFilter.classFilter.length) {
                setFilteredClass(() => {
                    return classes.filter(
                        (el) =>
                            // @ts-ignore
                            el.name
                                .toLowerCase()
                                .search(
                                    keyOfFilter.classFilter.toLowerCase()
                                ) >= 0
                    );
                });
            } else {
                setFilteredClass(classes);
            }
        }
    }, [keyOfFilter]);

    function confirmStudentsToAdd(e, student) {
        let arrStudent = [...studentConfirmed];
        arrStudent.push(student);
        addSelectedStudent(e, student?.['id']);

        setStudentConfirmed(arrStudent);

        setKeyOfFilter({ ...keyOfFilter, studentFilter: '' });
        // setStudentsForShow(arrStudent);
    }

    useEffect(() => {
        studentConfirmed.shift();
        studentsForShow.shift();
    }, []);

    function DeleteConfirmedStudents(e, id) {
        let arr = [...studentConfirmed];
        let indexOfId = -1;
        studentConfirmed.forEach((item, index) => {
            if (item?.['id'] === id) {
                indexOfId = index;
            }
        });

        arr.splice(indexOfId, 1);
        setStudentConfirmed(arr);
    }

    function addFacultyToConfirmationTable(e, classId) {
        let arr = [...studentsForShow];

        let arrConfirmed = [...studentConfirmed];

        students.forEach((item) => {
            if (item['classId'] === classId) {
                arr.push(item);
                addSelectedStudent(e, item?.['id']);
            }
        });

        setStudentConfirmed(arrConfirmed);
        // setStudentsForShow(arr);
    }

    useEffect(() => {
        async function fetchStudentInSubject() {
            let arr = [...studentsForShow];
            try {
                dispatch(setLoading(true));
                const res: any = await $api.student.getAll();

                // const a = res.result.map((item) => {
                // 	return item;
                // });

                res.result.forEach((item) => {
                    arr.push(item);
                });
                setStudentsForShow(arr);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        fetchStudentInSubject();
    }, []);

    function updateListStudentConfirmed(e) {
        studentConfirmed.forEach((item) => {
            addSelectedStudent(e, item?.['id']);
        });

        createStudentInSubject();
    }

    function renderSelected() {
        let arr: any = [...studentsForShow];
        let arr1: any = [...studentsSelected];
        let newArr: any = [];
        arr.forEach((item, index) => {
            if (arr1.includes(item.id)) {
                newArr.push(item);
            }
        });

        return (
            <tbody className={'divide-y divide-gray-200 dark:divide-neutral-700'}
            >
            {newArr.map((item, index) => {
                return (
                    <tr key={index} className={'odd:bg-white even:bg-gray-100 dark:odd:bg-neutral-900 dark:even:bg-neutral-800"'}>
                        <th scope='row' style={{ width: '8%' }}  className={'px-2 py-2'}>
                            <img
                                src={
                                    item?.['profilePicUrl'] ||
                                    _ENUMS.IMAGE_PROFILE
                                }
                                alt='Photo'
                            />
                        </th>
                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['fullName']}</td>
                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['className']}</td>
                        <td className={'px-2 py-2 text-sm text-right font-medium text-gray-800 dark:text-neutral-200'}>
                            <div
                                onClick={(e) =>
                                    deleteStudentInSubject(e, item?.['id'])
                                }>
                                <button type='button'
                                        className='text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 me-1 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
                                    <i className='fa fa-trash'></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        );
    }

    // -----------------------------------------------

    // @ts-ignore
    // @ts-ignore
    return (
        <div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />
            <div className='row'>
                <div className='col col-5 wrapper col-student'>
                    <h5 className={'font-bold uppercase'}>Sinh viên</h5>
                    <input
                        className='input-custom appearance-none block
                         w-full px-3 py-2 border border-gray-300
                         placeholder-gray-500 rounded-md
                         focus:outline-none focus:ring-1
                         focus:ring-blue-700" mt-2 mb-2'
                        type='text'
                        value={keyOfFilter.studentFilter}
                        onChange={(e) =>
                            setKeyOfFilter({
                                ...keyOfFilter,
                                studentFilter: e.target.value,
                                actions: 'student'
                            })
                        }
                        placeholder='Nhập tên sinh viên cần tìm'
                    />

                    <table className='min-w-full divide-y divide-gray-200 dark:divide-neutral-700 w-100'>
                        <thead>
                        <tr>
                            <th scope='col' className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Tên</th>
                            <th scope='col' className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Lớp</th>
                            <th scope='col' className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}></th>
                        </tr>
                        </thead>
                        <tbody
                            className={'divide-y divide-gray-200 dark:divide-neutral-700'}
                            style={{
                                maxHeight: 300,
                                overflowY: 'scroll'
                            }}>
                        {
                            keyOfFilter.studentFilter == '' ? studentRecommends.map((item, index) => {
                                return (
                                    <tr key={index}
                                        className={'odd:bg-white even:bg-gray-100 dark:odd:bg-neutral-900 dark:even:bg-neutral-800"'}>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['fullName']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['className']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>
                                            <div
                                                onClick={(e) =>
                                                    confirmStudentsToAdd(
                                                        e,
                                                        item
                                                    )
                                                }>
                                                <button type='button'
                                                        className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 me-1 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
                                                    <i className='fa fa-plus'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }) : filteredStudents.map((item, index) => {
                                return (
                                    <tr key={index}
                                        className={'odd:bg-white even:bg-gray-100 dark:odd:bg-neutral-900 dark:even:bg-neutral-800"'}>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['fullName']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['className']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>
                                            <div
                                                onClick={(e) =>
                                                    confirmStudentsToAdd(
                                                        e,
                                                        item
                                                    )
                                                }>
                                                <button type='button'
                                                        className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 me-1 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
                                                    <i className='fa fa-plus'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className='col col-7 wrapper col-class'>
                    <h5 className={'font-bold uppercase'}>Lớp</h5>
                    <input
                        className='input-custom appearance-none block
                         w-full px-3 py-2 border border-gray-300
                         placeholder-gray-500 rounded-md
                         focus:outline-none focus:ring-1
                         focus:ring-blue-700" mt-2 mb-2'
                        type='text'
                        value={keyOfFilter.classFilter}
                        onChange={(e) =>
                            setKeyOfFilter({
                                ...keyOfFilter,
                                classFilter: e.target.value,
                                actions: 'class'
                            })
                        }
                        placeholder='Nhập tên lớp cần tìm'
                    />
                    <table className='min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
                        <thead>
                        <tr>
                            <th scope='col' className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Tên</th>
                            <th scope='col' className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Mã</th>
                            <th scope='col' className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Khoa</th>
                            <th scope='col' className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}></th>
                        </tr>
                        </thead>
                        <tbody
                            className={'divide-y divide-gray-200 dark:divide-neutral-700'}
                            style={{

                                maxHeight: 300,
                                overflowY: 'scroll'
                            }}>
                        {
                            keyOfFilter.classFilter == '' ? classRecommends.map((item, index) => {
                                return (
                                    <tr key={index} className={'odd:bg-white even:bg-gray-100 dark:odd:bg-neutral-900 dark:even:bg-neutral-800"'}>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['name']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['classCodeName']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['facultyName']}</td>
                                        <td className={'px-2 py-2 text-end text-sm font-medium'}>
                                            <div
                                                onClick={(e) =>
                                                    addFacultyToConfirmationTable(
                                                        e,
                                                        item?.['id']
                                                    )
                                                }>
                                                <button type='button'
                                                        className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 me-1 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
                                                    <i className='fa fa-plus'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }) : filteredClass.map((item, index) => {
                                return (
                                    <tr key={index}
                                        className={'odd:bg-white even:bg-gray-100 dark:odd:bg-neutral-900 dark:even:bg-neutral-800"'}>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['name']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['classCodeName']}</td>
                                        <td className={'px-2 py-2 text-sm font-medium text-gray-800 dark:text-neutral-200'}>{item?.['facultyName']}</td>
                                        <td className={'px-2 py-2 text-end text-sm font-medium'}>
                                            <div
                                                onClick={(e) =>
                                                    addFacultyToConfirmationTable(
                                                        e,
                                                        item?.['id']
                                                    )
                                                }>
                                                <button type='button'
                                                        className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 me-1 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
                                                    <i className='fa fa-plus'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='row'>
                <div className='col '>
                    <div className='col wrapper col-confirm p-0'>
                        <h2 className={'font-bold uppercase py-3'}>Danh sách ĐÃ CHỌN</h2>
                        <table className='min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
                            <thead>
                            <tr>
                                <th scope='col'
                                    className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Ảnh
                                </th>
                                <th scope='col'
                                    className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Tên
                                </th>
                                <th scope='col'
                                    className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}>Lớp
                                </th>
                                <th scope='col'
                                    className={'text-start text-xs font-bold text-gray-900 uppercase dark:text-neutral-500'}></th>
                            </tr>
                            </thead>
                            {renderSelected()}
                        </table>
                    </div>

                    <div className={'min-w-full flex flex-row justify-between py-3'}>
                        <div className='font-medium pt-2'>
                            Số lượng: <span className={'text-red-700 font-bold underline'}>{studentInSubject.length}</span> sinh viên
                        </div>

                        <div
                            onClick={(e) => updateListStudentConfirmed(e)}>
                            <Button color={'bg-blue-700'} hoverColor={'bg-blue-800'} label={'Cập nhật danh sách'} icon={<i className={'far fa-save'}></i>}></Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------------------------------------------------- */}
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </div>
    );
}
