import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { _AUTH } from 'constants/_auth';

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		loading: false,
		token: null,
		alert: {
			type: 'success',
			title: '',
		},
		user: {},
		error: '',
	},
	reducers: {
		logout: (state) => {
			state.token = null;
			localStorage.clear();
			window.location.href = '/auth';
		},
		setToken: (state) => {
			const token: any = localStorage.getItem(_AUTH.TOKEN_NAME);
			if (token) {
				state.token = token;
				state.user = jwt_decode(token);
			}
		},
		setAlert: (state, { payload }) => {
			// @ts-ignore
			state.alert.type = payload.type || 'success';
			state.alert.title = payload.title || '';
		},
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
	},
});

export const { logout, setToken, setAlert, setLoading } = authSlice.actions;

export default authSlice.reducer;
