// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';
import React from 'react';

function TableSubjectTeachingForTeacher(props) {

    function totalTaughtSubjectSchedule() {
        return props.data.reduce((sum, subject) => sum + subject.numberOfTaughtSubjectSchedule, 0);
    }

    function allTotalHours() {
        return props.data.reduce((sum, subject) => sum + subject.totalHours, 0);
    }

    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên môn
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên lịch học
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày bắt đầu
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày kết thúc
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số buổi dự kiến
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số buổi đã dạy
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số giờ
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Trạng thái
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item, index) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {index + 1}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex flex-row items-center text-sm text-gray-900'>
                            <div className={'px-1 position-relative'} onClick={(e) => props.noteClick(e, item)}>
                                <img src='/note-icon.png' alt='Ghi chú' width={32} height={32} />
                                <div
                                    className={'position-absolute bg-red-600 text-white flex flex-row justify-center items-center'}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        padding: '0.5rem',
                                        borderRadius: 50,
                                        top: -10,
                                        left: -2
                                    }}>{item.notes.length}</div>
                            </div>
                            {item.subjectName || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {item.name || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {item.startDate &&
                            moment(item.startDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='DD/MM/yyyy'>
                                    {item.startDate}
                                </Moment>
                            ) : (
                                <div>{item.startDate}</div>
                            )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {item.endDate &&
                            moment(item.endDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='DD/MM/yyyy'>
                                    {item.endDate}
                                </Moment>
                            ) : (
                                <div>{item.endDate}</div>
                            )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {item.totalSessions}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {item.numberOfTaughtSubjectSchedule}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900 text-center'>
                            {item.totalHours}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div
                            className={'flex items-start text-sm font-bold ' + (item.status == 0 ? 'text-gray-700' : item.status == 1 ? 'text-blue-700' : 'text-red-700')}>
                            {item.status == 0 ? 'Chưa bắt đầu' : item.status == 1 ? 'Đã bắt đầu' : 'Đã kết thúc'}
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                <td className={'font-bold text-left'} colSpan={9}>
                    <div className={'w-full flex flex-row flex-wrap items-center justify-between'}>
                        <div className={'flex flex-row'}>
                            <div className={'py-2'}>
                                Tổng số buổi đã dạy: <span
                                className={'text-red-600'}>{totalTaughtSubjectSchedule()}</span>
                            </div>
                            <div className={'py-2 pl-2'}>
                                Tổng số giờ dạy thực tế: <span
                                className={'text-red-600'}>{totalTaughtSubjectSchedule() * 3}</span>
                            </div>
                        </div>
                        <div className={'py-2'}>
                            Tổng số giờ môn học: <span
                            className={'text-red-600'}>{allTotalHours()}</span>
                        </div>

                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    );
}

export default TableSubjectTeachingForTeacher;
