import axiosClient from 'config/axiosClient';
import qs from 'qs';

const _url = '/Api';
const  scheduleService = {
    subjectSchedule_getAll: () => {
        return axiosClient.post(_url + '/SubjectSchedules/GetAll');
    },
    subjectSchedule_get: (id) => {
        return axiosClient.post(_url + '/SubjectSchedules/Get', { id: id });
    },
    subjectSchedule_getPaging: (id) => {
        return axiosClient.post(_url + '/SubjectSchedules/GetPaging', { subjectTeachingId: id });
    },
    subjectSchedule_getPagingOption: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/GetPaging', option);
    },
    subjectSchedule_create: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/Create', option);
    },
    subjectSchedule_createRepeat: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/CreateRepeat', option);
    },
    subjectSchedule_Update: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/Update', option);
    },
    subjectSchedule_Delete: (id) => {
        return axiosClient.post(_url + '/SubjectSchedules/Delete', { id: id });
    },
    subjectSchedule_update: (data) => {
        return axiosClient.post(_url + '/SubjectSchedules/Update', data);
    },
    subjectSchedulesImport: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/Import', option);
    },
    subjectSchedulesExport: (option) => {
        const queryString = qs.stringify(option, { arrayFormat: 'repeat' });
        return axiosClient.get(_url + `/SubjectSchedules/Export?${queryString}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
    subjectSchedulesExportForTeacher: (option) => {
        const queryString = qs.stringify(option, { arrayFormat: 'repeat' });
        return axiosClient.get(_url + `/SubjectSchedules/Export-for-teacher?${queryString}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
    subjectSchedules_GetListByDate: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/GetListByDate', option);
    },
    subjectSchedules_GetOptionSchedule: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/GetOptionSchedule', option);
    },
    subjectSchedules_GetOptionScheduleForTeacher: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/GetOptionScheduleForTeacher', option);
    },
    subjectSchedules_DeleteNote: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/DeleteNote', option);
    },
    subjectStudent_getPaging: (option) => {
        return axiosClient.post(_url + '/SubjectStudents/GetPaging', option);
    },
    subjectStudent_getStudentForSelect: (subjectTeachingId) => {
        return axiosClient.post(_url + '/SubjectStudents/GetStudentForSelect', { subjectTeachingId: subjectTeachingId });
    },
    subjectStudent_GetListRecommendation: (subjectTeachingId) => {
        return axiosClient.post(_url + '/SubjectStudents/GetListRecommendation', { subjectTeachingId: subjectTeachingId });
    },
    subjectStudent_create: (data) => {
        return axiosClient.post(_url + '/SubjectStudents/Create', data);
    },
    subjectStudent_update: (data) => {
        return axiosClient.post(_url + '/SubjectStudents/Update', data);
    },
    subjectTeaching_getPaging: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetPaging', option);
    },
    subjectTeaching_create: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/Create', option);
    },
    subjectTeaching_update: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/Update', option);
    },
    subjectTeaching_delete: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/Delete', { id: id });
    },
    subjectTeaching_get: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/Get', { id: id });
    },
    subjectTeaching_createNotification: (data) => {
        return axiosClient.post(_url + '/SubjectTeachings/CreateNotification', data);
    },
    subjectTeachingsGetAttendances: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetAttendances', { id: id });
    },
    subjectTeaching_GetByStudent: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetByStudentId', {id: id});
    },
    subjectTeaching_GetByTeacher: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetByTeacherId', option);
    },
    subjectTeaching_GetForTeacher: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetForTeacher', option);
    },
    subjectStudent_Export: (subjectTeachingId) => {
        return axiosClient.get(_url + '/SubjectStudents/Export', {
            params: {
                id: subjectTeachingId
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
};

export default scheduleService;
