import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import './faculties__list.scss';
import React, { useEffect, useRef, useState } from 'react';
import { $api } from 'services/service';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/authSlice';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import Calendar from '../../../components/Elements/Calender/Calendar';
import moment from 'moment';
import { useParams } from 'react-router';
import TableSubjectOnDayViewTypeGroupByDate
    from '../../../components/Elements/Table/Table-Subject-OnDay-View-Type-Group-By-Date';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SubjectAllFaculty() {
    let history = useHistory();
    const ref = useRef();
    const dispatch = useDispatch();
    // @ts-ignore
    const { fId, cId } = useParams();
    const [subjectOnDay, setSubjectOnDay] = useState([]);
    const [dateStr, setDateStr] = useState(new Date());
    const [facultyColor, setFacultyColor] = useState('');
    const [changes, setChanges] = useState(false);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [isMonth, setIsMonth] = useState({
        start: new Date(),
        end: new Date()
    });
    const [calendarSubject, setCalendarSubject] = useState([{
        id: '',
        title: '',
        start: '',
        end: '',
        backgroundColor: facultyColor ? facultyColor : '#1e3a8a',
        eventName: 'subject'
    }]);
    const [subjectTeaching, setSubjectTeaching] = useState({
        id: '',
        subjectId: '',
        name: '',
        startDate: new Date(),
        endDate: new Date(),
        startTime: '00:00',
        teacherId: '',
        roomIdDefault: ''
    });

    useEffect(() => {
        getSubjectOnDate(dateStr);
    }, [dateStr]);

    async function getSubjectOnDate(dateStr) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectSchedules_GetListByDate({
                'date': moment(dateStr).format('YYYY-MM-DD')
            });
            if (res && res.result) {
                const arraySubject = [];
                if (res.result.items.length > 0) {
                    setSubjectOnDay(res.result.items);
                    // @ts-ignore
                    ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start'
                    });
                    arraySubject.push({
                        // @ts-ignore
                        id: '',
                        // @ts-ignore
                        title: `<b>${'Hôm nay có: ' + res.result.totalRecord + ' buổi học'}</b>`,
                        // @ts-ignore
                        start: moment(dateStr).format('YYYY-MM-DD') + 'T00:01:30.43',
                        // @ts-ignore
                        end: moment(dateStr).format('YYYY-MM-DD') + 'T12:59:30.43',
                        // @ts-ignore
                        backgroundColor: '#1e3a8a',
                        // @ts-ignore
                        eventName: 'subject',
                        // @ts-ignore
                        scheduleName: ''
                    });
                    setCalendarSubject(arraySubject);
                } else {
                    arraySubject.push({
                        // @ts-ignore
                        id: '',
                        // @ts-ignore
                        title: `<b>${'Hôm nay chưa có buổi học'}</b>`,
                        // @ts-ignore
                        start: moment(dateStr).format('YYYY-MM-DD') + 'T00:01:30.43',
                        // @ts-ignore
                        end: moment(dateStr).format('YYYY-MM-DD') + 'T12:59:30.43',
                        // @ts-ignore
                        backgroundColor: '#1e3a8a',
                        // @ts-ignore
                        eventName: 'subject',
                        // @ts-ignore
                        scheduleName: ''
                    });
                    setCalendarSubject(arraySubject);

                    setSubjectOnDay([]);
                }
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }


    useEffect(() => {
        async function getTeachers() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.teacher.getAll();
                if (res.result.length !== 0) {
                    subjectTeaching.teacherId = res.result[0].id;
                    setSubjectTeaching({
                        ...subjectTeaching
                    });
                } else {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        teacherId: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        async function getRooms() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.room.getAll();
                if (res['result'].length !== 0) {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: res.result[0].id
                    });
                } else {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getRooms();
        getTeachers();
    }, []);


    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    function goToSchedule(id) {
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
        history.push(`/lich-hoc-mon-chitiet/${id}`);
    }

    // @ts-ignore
    return (
        <DefaultLayout>
            <div className='subject page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div>Thời khóa biểu toàn khóa</div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <Calendar
                        aspectRatio={3}
                        data={calendarSubject}
                        isEventClicked={id => {
                        }}
                        isEventDateClicked={dateStr =>  setDateStr(dateStr)}
                        isEventChangeMonthClicked={(startStr, endStr) => setIsMonth({
                            start: startStr,
                            end: endStr
                        })}
                    />
                </div>
                {
                    subjectOnDay.length > 0 ? // @ts-ignore
                        <div className='page__content bg-white mt-3' ref={ref}>
                            <TableSubjectOnDayViewTypeGroupByDate data={subjectOnDay}
                                               dateChoose={dateStr}
                                               clicked={(e, subjectTeachingId) => goToSchedule(subjectTeachingId)} />
                        </div> : ''
                }
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}


export default SubjectAllFaculty;
