import React, { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import Button from '../../components/Elements/Button/Button';
import { setLoading } from '../../redux/authSlice';
import { $api } from '../../services/service';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import './mark-board.scss';
import { Modal } from 'react-bootstrap';
import ErrorDialog from '../../components/Elements/ErrorDialog/ErrorDialog';
import fileDownload from 'js-file-download';
import moment from 'moment-timezone';

function MarkBoard() {
    let history = useHistory();
    const ref = useRef();
    const dispatch = useDispatch();
    // @ts-ignore
    const { fId, cId } = useParams();
    const [classChoose, setClassChoose] = useState('');
    const [faculties, setFaculties] = useState([]);
    const [semesterClass, setSemesterClass] = useState([]);
    const [faculty, setFaculty] = useState('');
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [modalExport, setModalExport] = useState({ isOpen: false, type: 'all' });
    const [exportParam, setExportParam] = useState({ classId: '', semester: '' });
    useEffect(() => {
        async function getFaculties() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.faculties.GetAllAndClass();
                if (res && res.result.length > 0) {
                    setFaculties(res.result);
                    if (fId && fId.length > 0) {
                        setFaculty(fId);
                    } else {
                        setFaculty(res.result[0].id);
                    }
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getFaculties();
    }, []);

    async function getSemesterByClass() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.semesterPlanning.getByClass(cId);
            if (res && res.result.length > 0) {
                setSemesterClass(res.result);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function getMarkDetails(e, faculty, classInfo) {
        e.stopPropagation();
        history.push(`/bang-diem/${faculty.id}/${classInfo.id}`);
        setClassChoose(() => classInfo.id);
    }

    function bindingClassChooseWithRequestParam() {
        if (fId && cId) {
            // @ts-ignore
            const faculty = faculties.find(faculty => faculty.id === fId);
            // @ts-ignore
            const classInfo = faculty.classes.find(cls => cls.id === cId);
            // @ts-ignore
            setClassChoose(() => `Khoa: ${faculty.name} - Lớp: ${classInfo.name}`);
            setExportParam({ ...exportParam, classId: classInfo.id });
            getSemesterByClass();
        } else {
            setClassChoose(() => '');
        }
    }

    useEffect(() => {
        if (faculties.length > 0) {
            bindingClassChooseWithRequestParam();
        }
    }, [cId, faculties]);

    function closeModal() {
        setModalExport({ isOpen: false, type: 'all' });
        setExportParam({ classId: cId, semester: '' });
    }

    function exportMarkSemester() {
        if (exportParam.classId == ''){
            show_ErrMsg('Vui lòng chọn lớp để xuất dữ liệu!');
            return
        }
        setModalExport({ isOpen: true, type: 'semester' });
    }

    function exportMarkAll() {
        if (exportParam.classId == ''){
            show_ErrMsg('Vui lòng chọn lớp để xuất dữ liệu!');
            return
        }
        setModalExport({ isOpen: true, type: 'all' });
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function exportData() {
        try {
            if (modalExport.type != 'all') {
                if(exportParam.semester == ''){
                    show_ErrMsg('Vui lòng chọn học kì!');
                    return
                }
                processApi()
            }else{
                if (exportParam.classId == ''){
                    show_ErrMsg('Vui lòng chọn lớp để xuất dữ liệu!');
                    return
                }
                processApi()
            }

        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }

        async function processApi() {
            dispatch(setLoading(true));
            const res: any = await $api.exam.examExportWithClassOption(exportParam.semester && exportParam.classId ? {
                classId: exportParam.classId,
                semester: exportParam.semester
            } : { classId: exportParam.classId });
            fileDownload(res, `Bang-diem-${modalExport.type == 'all' ? 'toan-khoa' : 'hoc-ki-' + exportParam.semester}-${classChoose}-ngay-xuat-ket-qua-${moment((new Date())).format('DD/MM/YYYY')}.xlsx`);
            dispatch(setLoading(false));
        }
    }

    return (
        <DefaultLayout>
            <div className='page mark-board'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <div>Bảng điểm tổng hợp</div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div className='group inline-block'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Lớp
                            </div>
                            <button
                                className='outline-none focus:outline-none border px-3 py-1 bg-white rounded-sm flex items-center min-w-64 text-1xl'
                            >
                                    <span
                                        className='pr-1 font-semibold flex-1'>{classChoose == '' ? 'Chọn lớp' : classChoose}</span>
                                <span>
                                      <svg
                                          className='fill-current h-4 w-4 transform group-hover:-rotate-180
                                        transition duration-150 ease-in-out'
                                          xmlns='http://www.w3.org/2000/svg'
                                          viewBox='0 0 20 20'
                                      >
                                        <path
                                            d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'
                                        />
                                      </svg>
                                    </span>
                            </button>
                            <ul
                                className='bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute
  transition duration-150 ease-in-out origin-top min-w-32 z-10 cursor-pointer'
                            >
                                {faculties.map((faculty: any) => {
                                    return (
                                        <li className='rounded-sm relative px-3 py-1 hover:bg-gray-100'
                                            key={faculty.id}>
                                            <button
                                                className='w-full text-left flex items-center outline-none focus:outline-none'
                                            >
                                                <span className='pr-1 flex-1'>{faculty.name}</span>
                                                <span className='mr-auto'>
                                                      <svg
                                                          className='fill-current h-4 w-4
                                                        transition duration-150 ease-in-out'
                                                          xmlns='http://www.w3.org/2000/svg'
                                                          viewBox='0 0 20 20'
                                                      >
                                                        <path
                                                            d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'
                                                        />
                                                      </svg>
                                                    </span>
                                            </button>
                                            <ul className='bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32'>
                                                {
                                                    faculty.classes.length > 0 ? faculty.classes.map(classItem => {
                                                        return <li key={classItem.id}
                                                            // @ts-ignore
                                                                   className='px-3 py-1 hover:bg-gray-100'
                                                                   onClick={(e) => getMarkDetails(e, faculty, classItem)}>{classItem.name}</li>;
                                                    }) : <li className='px-3 py-1 hover:bg-gray-100'>Chưa có lớp phù
                                                        hợp</li>
                                                }
                                            </ul>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className={'flex'}>
                            <div className='actions--item mr-1' onClick={() => exportMarkSemester()}>
                                <Button
                                    label='Xuất bảng điểm học kỳ'
                                    icon={<i className='fas fa-download' />}
                                    color={'bg-green-800'}
                                    hoverColor={'bg-green-900'}
                                />
                            </div>
                            <div className='actions--item' onClick={() => exportData()}>
                                <Button
                                    label='Xuất bảng điểm toàn khóa'
                                    icon={<i className='fas fa-download' />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    show={modalExport.isOpen}
                    aria-labelledby='contained-modal-title-vcenter'
                    onHide={() => {
                        closeModal();
                    }}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id='contained-modal-title-vcenter'>
                            <div className={'flex flex-column'}>
                                Xuất bảng điểm {modalExport.type == 'all' ? 'toàn khóa' : 'học kì'}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='mt-2 w-100'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Học kì
                            </div>
                            <select
                                className='input-custom block
												w-100 px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                value={exportParam.semester}
                                onChange={(e) => {
                                    setExportParam({ ...exportParam, semester: e.target.value });
                                }}>
                                <option
                                    value={''}>
                                    Chọn học kì
                                </option>
                                {semesterClass?.length > 0 ? semesterClass.map(record => {
                                        return <option
                                            value={record.semester}
                                            key={record.id}>
                                            Học kì: {record.semester}
                                        </option>;
                                    })
                                    : <option
                                        value={''}
                                        key={''}>
                                        Chọn học kì
                                    </option>}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: 'space-between' }}>
                        <div className={'flex flex-row justify-content-between w-100'}>
                            <div
                                onClick={() => {
                                    closeModal();
                                }}>
                                <Button color={'bg-red-600'} hoverColor={'bg-red-800'}
                                        icon={<i className='fas fa-times-circle'></i>} label='Hủy' />
                            </div>
                            <div className={'flex flex-row'}>
                                <div className={'mr-1'}
                                     onClick={() => {
                                         exportData();
                                     }}>
                                    <Button icon={<i className='fas fa-file-alt'></i>}
                                            label={'Xuất kết quả'} />
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default MarkBoard;