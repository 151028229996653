import React, { Fragment } from 'react';
import './nav-bar.scss';
import { Menu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import user_icon from '../../../assets/images/user-icon.svg';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

function NavBar(props) {
	// @ts-ignore
	const user = useSelector((state) => state.auth.user);
	return (
		<div className='nav-bar shadow-sm bg-white'>
			<div className='_wrapper flex items-center justify-between'>
				<div className='nav-bar__left flex items-center'>
					<div
						className='bars mr-2 d-icon'
						onClick={props.hideClicked}>
						<i className='fa fa-bars text-xl' />
					</div>
					<div
						className='logo flex items-center cursor-pointer'
						onClick={() => {
							window.location.href = '/';
						}}>
						<img
							className='img-logo rounded-full'
							src='/logo.png'
							alt='Cao Đẳng Tây Đô'
							title='Cao Đẳng Tây Đô'
						/>
						<div className='name font-bold h-fit color-sapphire ml-2'>
							Cao Đẳng Tây Đô
						</div>
					</div>
				</div>
				<div className='nav-bar__right'>
					<div className='menu flex items-center'>
						<div className='menu--item'>
							<Menu as='div' className='ml-3 relative'>
								<div>
									<Menu.Button
										className='max-w-xs rounded-full flex items-center w-8 h-8 hover:bg-violet-600'>
										<span className='sr-only'>
											Open user menu
										</span>
										<img
											className='h-8 w-8 rounded-full'
											src={user_icon}
											alt=''
										/>
									</Menu.Button>
								</div>
								<Transition
									as={Fragment}
									enter='transition ease-out duration-100'
									enterFrom='transform opacity-0 scale-95'
									enterTo='transform opacity-100 scale-100'
									leave='transition ease-in duration-75'
									leaveFrom='transform opacity-100 scale-100'
									leaveTo='transform opacity-0 scale-95'>
									<Menu.Items
										className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-sm py-1 bg-white ring-1 ring-blue-700 ring-opacity-5 focus:outline-none'>
										<Menu.Item>
											{({ active }) => (
												<div
													className={classNames(
														active
															? 'bg-gray-100'
															: '',
														'block px-4 py-2 text-sm text-gray-700 font-bold cursor-pointer'
													)}>
													<div>Hi, {user.Name}</div>
												</div>
											)}
										</Menu.Item>
										<Menu.Item
											// @ts-ignore
											onClick={props.changePassword}>
											{({ active }) => (
												<div
													className={classNames(
														active
															? 'bg-gray-100'
															: '',
														'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
													)}>
													<i className='w-8 far fa-lock' />
													Đổi mật khẩu
												</div>
											)}
										</Menu.Item>
										<Menu.Item
											// @ts-ignore

											onClick={props.logOut}>
											{({ active }) => (
												<div
													className={classNames(
														active
															? 'bg-gray-100'
															: '',
														'block px-4 py-2 text-sm text-red-500 cursor-pointer'
													)}>
													<i className='w-8 far fa-sign-out' />
													Đăng xuất
												</div>
											)}
										</Menu.Item>
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NavBar;
